body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'GHEA Grapalat';
  src:
    local('GHEA Grapalat'),
    url('./fonts/GHEAGrpalatReg.otf') format('truetype');
}

@font-face {
  font-family: 'Arian AMU';
  src:
    local('Arian AMU'),
    url('./fonts/arnamu.ttf') format('truetype');
}

@font-face {
  font-family: 'Arial AMU';
  src:
    local('Arial AMU'),
    url('./fonts/arialamu.ttf') format('truetype');
}

@media print {
  body * {
    visibility: hidden;
  }

  .page,
  .print * {
    visibility: visible;
  }

  .page,
  .print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

/* @media print {
  body,
  page {
    margin: 0;
    box-shadow: 0;
  }
} */
@media print {
  @page {
    size: A4;
    margin: 16mm 16mm 16mm 16mm;
  }

  html,
  body {
    width: 210mm;
    height: 297mm;
    width: 21cm;
    height: 29.7cm;
  }

  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
  }

  .page-break {
    page-break-after: always;
  }
}

.ant-radio-wrapper-disabled,
.ant-checkbox-wrapper-disabled span,
.ant-input-disabled,
.ant-select-disabled span {
  color: black !important;
  opacity: 1 !important;
}